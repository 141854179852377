<template>
  <div>
    <v-form ref="form">
      <v-card elevation="2" outlined class="mx-auto" max-width="800">
        <v-card-title>Nuevo escenario</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="desc"
            label="Descripción"
            prepend-icon="mdi-text"
            :rules="[rules.required]"
            autocomplete="off"
            :disabled="submited"
          ></v-text-field>
          <v-file-input
            v-model="file"
            show-size
            small-chips
            truncate-length="20"
            label="Archivo"
            accept=".xlsx"
            :rules="[rules.required]"
            :disabled="submited"
          ></v-file-input>
          <v-select
            v-model="file_type"
            prepend-icon="mdi-chevron-double-down"
            :items="['NORMAL', 'PREPROCESO']"
            label="Tipo Archivo"
            :rules="[rules.required]"
          ></v-select>
          <v-checkbox
            v-model="inter_depo"
            label="Permitir interlineado en depósito, de servicios de una misma unidad de negocio que están asignados a depósitos distintos"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              color="indigo darken-4"
              dark
              @click="submit"
              :disabled="submited"
            >
              Crear
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      type="success"
      class="mt-6 mx-auto"
      max-width="300"
      elevation="5"
      transition="scroll-y-transition"
      :value="alert"
    >
      Escenario ingresado
    </v-alert>
  </div>
</template>
<script>
import { customAlphabet } from "nanoid";
import { alphanumeric } from "nanoid-dictionary";
const nanoid = customAlphabet(alphanumeric, 20);
import { firebase } from "@/firebase";

export default {
  data() {
    return {
      submited: false,
      desc: "",
      file: null,
      file_type: null,
      inter_depo: false,
      alert: false,
      rules: {
        required: (value) => !!value || "Requerido.",
      },
    };
  },
  watch: {
    desc: function (val) {
      this.desc = val.toUpperCase();
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.submited = true;
      const uuid = nanoid();
      const payload = {
        uuid: uuid,
        user_uid: this.$store.state.user.uid,
        user_name: this.$store.state.user.name,
        creation_date: new Date().toLocaleDateString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        creation_time: new Date().toLocaleTimeString([], { hour12: false }),
        creation_timestamp: Date.now() / 1000,
        creation_datetime: `${new Date().toLocaleDateString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })} ${new Date().toLocaleTimeString([], { hour12: false })}`,
        state: 0,
        state_desc: "INGRESADO",
        description: this.desc,
        inter_depo: this.inter_depo,
        file_type: this.file_type,
      };
      await firebase
        .storage()
        .ref()
        .child(`validar_gis/carga_${uuid}.xlsx`)
        .put(this.file);
      await firebase
        .firestore()
        .collection("scenarios")
        .doc(payload.uuid)
        .set(payload);
      this.alert = true;
      setTimeout(
        () =>
          this.$router.push({
            name: "scenarios",
          }),
        2000
      );
    },
  },
};
</script>
